import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import reducers from './store/reducers';
import middleware from './store/middleware';
import localStorage from 'local-storage';
import { sessionService } from 'redux-react-session';
import * as ReactGA from 'react-ga';

import 'materialize-css/sass/materialize.scss';
import './css/styles.scss';
import './css/animation.scss';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
  debug: process.env.REACT_APP_ENVIRONMENT === 'test',
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: process.env.REACT_APP_GA_SAMPLE_SIZE
  }
});

//Initial state - load from local storage if possible
const plan = localStorage.get('lcpState') ? JSON.parse(localStorage.get('lcpState')) : {
  season: 'spring',
  grassName: 'Unknown',
  grassSeason: 'cool',
  sunniness: 'sun',
  lawnState: 'good',
  toolEffort: 'medium',
  zip: '',
  state: '',
  hasHighTraffic: false,
  hasPetSpots: false,
  hasTractor: false,
  useOrganicFertilizers: false
};

if('grassType' in plan){
  plan.grassSeason = plan.grassType;
  delete plan.grassType;
}

//Clear out products, items, and activities upon reload or for new visitors - forces a refresh when visiting the site
plan.activities = [];
plan.tools = [];
plan.products = [];
plan.displayedActivities = [];
plan.displayedItems = [];
plan.cacheTime = null;
plan.maxPrice = 0;
plan.receivePlanReminders = true;
plan.receivePromotionalEmails = false;

const store = createStore(reducers, {plan}, middleware);
sessionService.initSessionService(store);

sessionService.loadUser().then(() => {
  ReactGA.set({ dimension1: 'Logged In' });
}).catch(() => {
  ReactGA.set({ dimension1: 'Not Logged In' });
});

//Syncs the store with local storage
store.subscribe(() => {
  localStorage.set('lcpState', JSON.stringify(store.getState().plan));
});

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);
