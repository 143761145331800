import * as actions from '../actions';
import {
  COMPARE_EQUAL,
  SORT_AFTER,
  SORT_BEFORE,
  SORT_EQUAL,
  ZERO
} from '../constants';
import {LawnActivityProductCombo} from '../../models';

function plan(state = {}, action) {
  switch (action.type) {
    case actions.SET_GRASS_SEASON:
      return Object.assign({}, state, {
        grassSeason: action.grassSeason,
        displayedItems: calculateDisplayedItems(state, {grassSeason: action.grassSeason})
      });
    case actions.SET_SEASON:
      return Object.assign({}, state, {
        season: action.season,
        displayedItems: calculateDisplayedItems(state, {season: action.season})
      });
    case actions.SET_ACTIVITY:
      return Object.assign({}, state, {
        activity: action.activity
      });
    case actions.SET_GRASS:
      return Object.assign({}, state, {
        grassName: action.grassName,
        grassSeason: action.grassSeason,
        displayedItems: calculateDisplayedItems(state, {grassSeason: action.grassSeason, grassName: action.grassName})
      });
    case actions.SET_LAWN_STATE:
      return Object.assign({}, state, {
        lawnState: action.lawnState
      });
    case actions.SET_SUNNINESS:
      return Object.assign({}, state, {
        sunniness: action.sunniness,
        displayedItems: calculateDisplayedItems(state, {sunniness: action.sunniness})
      });
    case actions.SET_TOOL_EFFORT:
      return Object.assign({}, state, {
        toolEffort: action.toolEffort,
        displayedItems: calculateDisplayedItems(state, {toolEffort: action.toolEffort})
      });
    case actions.SET_MAX_PRICE:
      return Object.assign({}, state, {
        maxPrice: action.price
      });
    case actions.SET_STATE_AND_ZIP:
      return Object.assign({}, state, {
        state: action.state,
        zip: action.zip,
        displayedItems: calculateDisplayedItems(state, {state: action.state, zip: action.zip})
      });
    case actions.SET_HAS_HIGH_TRAFFIC:
      return Object.assign({}, state, {
        hasHighTraffic: action.hasHighTraffic,
        displayedItems: calculateDisplayedItems(state, {hasHighTraffic: action.hasHighTraffic})
      });
    case actions.TOGGLE_HAS_HIGH_TRAFFIC:
      return Object.assign({}, state, {
        hasHighTraffic: !state.hasHighTraffic,
        displayedItems: calculateDisplayedItems(state, {hasHighTraffic: !state.hasHighTraffic})
      });
    case actions.SET_HAS_PET_SPOTS:
      return Object.assign({}, state, {
        hasPetSpots: action.hasPetSpots,
        displayedItems: calculateDisplayedItems(state, {hasPetSpots: action.hasPetSpots})
      });
    case actions.TOGGLE_HAS_PET_SPOTS:
      return Object.assign({}, state, {
        hasPetSpots: !state.hasPetSpots,
        displayedItems: calculateDisplayedItems(state, {hasPetSpots: !state.hasPetSpots})
      });
    case actions.SET_USE_ORGANIC_FERTILIZERS:
      return Object.assign({}, state, {
        useOrganicFertilizers: action.useOrganicFertilizers,
        displayedItems: calculateDisplayedItems(state, {useOrganicFertilizers: action.useOrganicFertilizers})
      });
    case actions.TOGGLE_USE_ORGANIC_FERTILIZERS:
      return Object.assign({}, state, {
        useOrganicFertilizers: !state.useOrganicFertilizers,
        displayedItems: calculateDisplayedItems(state, {useOrganicFertilizers: !state.useOrganicFertilizers})
      });
    case actions.SET_HAS_TRACTOR:
      return Object.assign({}, state, {
        hasTractor: action.hasTractor,
        displayedItems: calculateDisplayedItems(state, {hasTractor: action.hasTractor})
      });
    case actions.TOGGLE_HAS_TRACTOR:
      return Object.assign({}, state, {
        hasTractor: !state.hasTractor,
        displayedItems: calculateDisplayedItems(state, {hasTractor: !state.hasTractor})
      });
    case actions.SET_PLAN:
      return Object.assign({}, state, {
        grassName: action.plan.grass_name,
        grassSeason: action.plan.grass_season,
        hasHighTraffic: action.plan.has_high_traffic,
        hasPetSpots: action.plan.has_pet_spots,
        useOrganicFertilizers: action.plan.use_organic_fertilizers,
        lawnState: action.plan.lawn_state,
        sunniness: action.plan.sunniness,
        toolEffort: action.plan.tool_effort,
        state: action.plan.state,
        zip: action.plan.zip,
        receivePlanReminders: action.plan.receive_plan_reminders,
        receivePromotionalEmails: action.plan.receive_promotional_emails,
        displayedItems: calculateDisplayedItems(state, {
          grassName: action.plan.grass_name,
          grassSeason: action.plan.grass_season,
          hasHighTraffic: action.plan.has_high_traffic,
          hasPetSpots: action.plan.has_pet_spots,
          useOrganicFertilizers: action.plan.use_organic_fertilizers,
          lawnState: action.plan.lawn_state,
          sunniness: action.plan.sunniness,
          toolEffort: action.plan.tool_effort,
          state: action.plan.state,
          zip: action.plan.zip,
        })
      });
    case actions.SET_ACTIVITIES:
      // Loads activities from API call into memory, transforms name in url slug in the process
      return Object.assign({}, state, {
        activities: action.activities.map(a => Object.assign({}, a, {urlSlug: a.name.toLowerCase().split(' ').join('-')})),
        displayedItems: calculateDisplayedItems(state, {activities: action.activities})
      });
    case actions.SET_PRODUCTS:
      return Object.assign({}, state, {
        products: action.products,
        displayedItems: calculateDisplayedItems(state, {products: action.products})
      });
    case actions.SET_TOOLS:
      return Object.assign({}, state, {
        tools: action.tools,
        displayedItems: calculateDisplayedItems(state, {tools: action.tools})
      });
    case actions.UPDATE_CACHE_TIME:
      return Object.assign({}, state, {
        cacheTime: action.time
      });
    case actions.UPDATE_DISPLAYED_ITEMS:
      return Object.assign({}, state, {
        displayedItems: calculateDisplayedItems(state)
      });
    case actions.TOGGLE_PROMOTIONAL_EMAILS:
      return Object.assign({}, state, {
        receivePromotionalEmails: !state.receivePromotionalEmails
      });
    case actions.TOGGLE_PLAN_REMINDERS:
      return Object.assign({}, state, {
        receivePlanReminders: !state.receivePlanReminders
      });
    default:
      return state;
  }
}

/**
 * Sort activities by order
 * @param a {{display_order: Number}}
 * @param b {{display_order: Number}}
 * @returns {number}
 */
const sortActivities = (a, b) => {
  //Sort by Order, lowest to highest
  if (a.display_order < b.display_order) {
    return SORT_BEFORE;
  } else if (a.display_order > b.display_order) {
    return SORT_AFTER;
  }
  return SORT_EQUAL;
};

/**
 * Sort products by type
 * @param grassName {string}
 * @returns {number}
 */
const sortProducts = grassName => {
  return (a, b) => {
    //Check if a product does not have any breeds
    if(b.applicable_grasses.length === ZERO){
      if(a.applicable_grasses.length === ZERO){
        return SORT_EQUAL;
      } else {
        return SORT_BEFORE;
      }
    } else if(a.applicable_grasses.length === ZERO){
      return SORT_AFTER;
    }

    //If one product is for chosen grass it should come before a product that is not
    if(a.applicable_grasses.includes(grassName.toLowerCase()) && !b.applicable_grasses.includes(grassName.toLowerCase())){
      return SORT_BEFORE;
    } else if(!a.applicable_grasses.includes(grassName.toLowerCase()) && b.applicable_grasses.includes(grassName.toLowerCase())){
      return SORT_AFTER;
    }

    //Sort by products with the fewest breeds first
    if (a.applicable_grasses.length < b.applicable_grasses.length) {
      return SORT_BEFORE;
    } else if (a.applicable_grasses.length > b.applicable_grasses.length) {
      return SORT_AFTER;
    }
    return SORT_EQUAL;
  };
};

/**
 * Gets the activities shows for the given grass and season
 * @param activities []
 * @param season {string}
 * @param grassSeason {string}
 * @return {[]}
 */
const getDisplayedActivitiesForSeasonAndGrass = (activities, season, grassSeason) => {
  const displayedActivities = activities.filter(activity => activity.season === season && activity.grass_season === grassSeason);
  displayedActivities.sort(sortActivities);
  return displayedActivities;
};

/**
 * Setup dictionary objects for tools and products
 * Key is activity, value is an empty array where products/tools will be loaded
 * Improved performance by only iterating over displayedActivities once to create both dictionaries
 * @param displayedActivities
 * @return {{toolsByActivity: *, productsByActivity: *}}
 */
const getItemsForActivity = displayedActivities => {
  const productsByActivity = {};
  const toolsByActivity = {};
  displayedActivities.forEach(activity => {
    const key = String(activity.name).toUpperCase();
    if(!(key in productsByActivity)) {
      productsByActivity[key] = [];
    }
    if(!(key in toolsByActivity)) {
      toolsByActivity[key] = [];
    }
  });

  return {productsByActivity, toolsByActivity};
};

/**
 * Tests if given item is applicable to the chosen grass and grass season
 * @param item {{applicable_grasses: [string], applicable_grass_seasons: [string]}}
 * @param grassName {string} Name of the grass the customer has chosen for their plan
 * @param grassSeason {string} Grass season (warm or cool) that the customer has chosen for their plan
 * @return {boolean} if item is applicable
 */
const isApplicableGrassFilter = (item, grassName, grassSeason) => {
  const isGrassSeason = item.applicable_grass_seasons.includes(grassSeason.toLowerCase());
  const isGrassSpecific = item.applicable_grasses.length !== ZERO;
  const isGrass = item.applicable_grasses.includes(grassName);
  return !isGrassSpecific || isGrass || isGrassSeason;
};

/**
 * Tests if given item is applicable to chosen State
 * @param item {{applicable_states: [string]}}
 * @param state {string} The US State that the customer has chosen for their location (via zip code)
 * @return {boolean} if item is applicable
 */
const isApplicableLocationFilter = (item, state) => {
  return state === '' || item.applicable_states.includes(state) || item.applicable_states.length === ZERO;
};

/**
 * Tests if given item is applicable to chosen lawn state
 * @param item {{applicable_sunniness: [string], has_pet_spots: boolean, has_high_traffic: boolean, use_organic_fertilizers: true}}
 * @param sunniness {string} The level of sunniness the customer has chosen for their plan
 * @param hasPetSpots {boolean} Whether their lawn has pet spots that the customer has chosen for their plan
 * @param hasHighTraffic {boolean} Whether their lawn has high traffic that the customer has chosen for their plan
 * @param useOrganicFertilizers {boolean} Whether they care to use organic fertilizers that the customer has chosen for their plan
 * @return {boolean} if item is applicable
 */
const isApplicableLawnHealthFilter = (item, sunniness, hasPetSpots, hasHighTraffic, useOrganicFertilizers) => {
  const sunninessFilter = item.applicable_sunniness.length === ZERO || item.applicable_sunniness.includes(sunniness.toLowerCase());
  const hasPetSpotsFilter = hasPetSpots ? item.has_pet_spots : true;
  const hasHighTrafficFilter = hasHighTraffic ? item.has_high_traffic : true;
  const useOrganicFertilizersFilter = useOrganicFertilizers ? item.use_organic_fertilizers : true;

  return sunninessFilter && hasPetSpotsFilter && hasHighTrafficFilter && useOrganicFertilizersFilter;
};

/**
 * Load applicable products using given filters and displayed activities
 * @param productsByActivity
 * @param store
 * @return {*}
 */
const loadProductsForDisplayedActivities = (productsByActivity, store) => {
  const {products, grassName, grassSeason, sunniness, state, hasPetSpots, hasHighTraffic, useOrganicFertilizers} = store;

  products.forEach(item => {
    const key = String(typeof item.activity === 'string' ? item.activity : item.activity.name).toUpperCase();

    const lawnHealthFilter = isApplicableLawnHealthFilter(item, sunniness, hasPetSpots, hasHighTraffic, useOrganicFertilizers);
    const grassFilter = isApplicableGrassFilter(item, grassName, grassSeason);
    const locationFilter = isApplicableLocationFilter(item, state);

    if(key in productsByActivity && lawnHealthFilter && grassFilter && locationFilter) {
      productsByActivity[key].push(item);
    }
  });

  //Sort products by their specificity to the chosen grass
  for(const activity in productsByActivity){
    productsByActivity[activity].sort(sortProducts(grassName));
  }

  return productsByActivity;
};

/**
 * Load applicable tools using given filters and displayed activities
 * @param toolsByActivity
 * @param store
 * @return {*}
 */
const loadToolsForDisplayedActivities = (toolsByActivity, store) => {
  const {tools, toolEffort} = store;

  tools.forEach(tool => {
    const key = String(typeof tool.activity === 'string' ? tool.activity : tool.activity.name).toUpperCase();
    const effortLevelFilter = tool.effort_level.localeCompare(toolEffort) === COMPARE_EQUAL;

    if(key in toolsByActivity && effortLevelFilter) {
      toolsByActivity[key].push(tool);
    }
  });

  return toolsByActivity;
};

/**
 * @param store {{}} Current state of the store
 * @param update {{}} Any updates from the action to make to the store before calculating displayed items
 * @returns {[{activity: string, applicable_grass_seasons: [string], applicable_sunniness: [string], applicable_states: [string], has_pet_spots: boolean, has_high_traffic: true, use_organic_fertilizers: boolean}|{activity: string, effort_level: string}]}
 */
const calculateDisplayedItems = (store, update = {}) => {
  store = Object.assign({}, store, update);

  const {activities, products, tools, grassSeason, season} = store;

  if(activities.length === ZERO || products.length === ZERO || tools.length === ZERO){
    //Still loading from DB
    return [];
  }

  //Load tools and products based on given filters and displayed activities for season and grass
  const displayedActivities = getDisplayedActivitiesForSeasonAndGrass(activities, season, grassSeason);
  let {productsByActivity, toolsByActivity} = getItemsForActivity(displayedActivities);
  productsByActivity = loadProductsForDisplayedActivities(productsByActivity, store);
  toolsByActivity = loadToolsForDisplayedActivities(toolsByActivity, store);

  // Find a single product to display for each activity and load into state
  const displayedItems = [];
  displayedActivities.forEach(activity => {
    const key = String(activity.name).toUpperCase();
    if(key in productsByActivity && productsByActivity[key].length > ZERO) {
      const product = productsByActivity[key][ZERO];
      product.activity = activity;
      displayedItems.push(new LawnActivityProductCombo(product));
    } else if (key in toolsByActivity && toolsByActivity[key].length > ZERO) {
      const tool = toolsByActivity[key][ZERO];
      tool.activity = activity;
      displayedItems.push(new LawnActivityProductCombo(tool));
    }
  });

  return displayedItems;
};

export default plan;
