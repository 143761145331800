import LawnProduct from './LawnProduct';

/**
 * A LawnProduct and LawnActivity combo
 * @typedef LawnActivityProductCombo
 */
class LawnActivityProductCombo extends LawnProduct {

  /**
   * @constructor
   * @override
   * @param data {{}}
   */
  constructor(data){
    super(data);
    const {
      name,
      activity
    } = data;

    /**
     * LawnActivity's name
     * @type {String}
     */
    this.name = name;

    /**
     * LawnActivity object
     * @type {LawnActivity}
     */
    this.activity = activity;
  }

  /**
   * Returns a simple JSON object representation of the LawnActivityProductCombo
   * @override
   * @return {{}}
   */
  data = () => {
    return Object.assign({}, super.data(), {
      name: this.name,
      activity: this.activity
    });
  }
}

export default LawnActivityProductCombo;
