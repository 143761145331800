import { combineReducers } from 'redux';
import { sessionReducer as session } from 'redux-react-session';
import plan from './plan';
import api from './api';
import auth from './auth';

export default combineReducers({
  plan,
  api,
  auth,
  session
});
