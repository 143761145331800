/**
 * @typedef BaseModel
 */
class BaseModel {

  /**
   * @constructor
   * @param data {{}}
   */
  constructor(data){
    /**
     * @type {{}}
     */
    this.rawData = data;
  }

  /**
   * @return {{}}
   */
  data = () => this.rawData;
}

export default BaseModel;
