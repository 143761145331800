import * as actions from '../actions';

export const getStateFromZip = zip => ({
  type: actions.GET_STATE_FROM_ZIP,
  zip: zip,
  meta: {
    throttle: 10000
  }
});

export const loadTools = () => ({
  type: actions.LOAD_TOOLS,
  meta: {
    throttle: 10000
  }
});

export const loadProducts = () => ({
  type: actions.LOAD_PRODUCTS,
  meta: {
    throttle: 10000
  }
});

export const loadActivities = () => ({
  type: actions.LOAD_ACTIVITIES,
  meta: {
    throttle: 10000
  }
});

export const loadPlan = () => ({
  type: actions.LOAD_PLAN,
  meta: {
    throttle: 1200
  }
});

export const savePlan = () => ({
  type: actions.SAVE_PLAN,
  meta: {
    throttle: 1200
  }
});

export const getAuthorizationToken = () => ({
  type: actions.GET_AUTHORIZATION_TOKEN
});

export const setAuthorizationToken = token => ({
  type: actions.SET_AUTHORIZATION_TOKEN,
  token: token
});

export const setAuthorizationTokenRefreshTimer = () => ({
  type: actions.SET_AUTHORIZATION_TOKEN_REFRESH_TIMER
});
