/* eslint-disable no-console */
import * as actions from '../actions';
import 'whatwg-fetch';
import {
  setPlan
} from '../actions/plan';
import {sessionService} from 'redux-react-session';
import * as ReactGA from 'react-ga';

/**
 * Creates default GET request options
 * @param token {?null | string} Signed JWT
 * @param method {'get' | 'put'}
 * @param body {?null | object}
 */
function requestOptions(token = null, method = 'get', body = null) {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: method,
    body: body,
    mode: 'cors'
  };

  if(token !== null){
    options.headers.Authorization = token;
  }

  return options;
}

async function userApiCall(apiPath, method = 'get', body = null){
  const session = await sessionService.loadSession();
  const response = await fetch(`https://${process.env.REACT_APP_API_BASE_URL}/${apiPath}`, requestOptions(session.token, method, body));
  return response.json();
}

const auth = store => next => async action => {
  switch(action.type){
    case actions.PROCESS_LOGIN:
      await sessionService.saveSession({token: action.token});
      await sessionService.saveUser({
        userId: action.id,
        email: action.email,
        token: action.token
      });
      await new Promise(resolve => {
        ReactGA.set({ dimension1: 'Logged In' });
        resolve();
      });
      // eslint-disable-next-line no-case-declarations
      const response = await userApiCall('plans');
      store.dispatch(setPlan(response.plan));
      action.history.push('/');
      break;
    default:
      break;
  }
  return next(action);
};

export default auth;
