import * as actions from '../actions';

// Action Creators
export const setGrass = (grassName, grassSeason) => ({
  type: actions.SET_GRASS,
  grassName: grassName,
  grassSeason: grassSeason
});

export const setLawnState = lawnState => ({
  type: actions.SET_LAWN_STATE,
  lawnState: lawnState,
});

export const setSeason = season => ({
  type: actions.SET_SEASON,
  season: season
});

export const setActivity = activity => ({
  type: actions.SET_ACTIVITY,
  activity: activity
});

export const setSunniness = sunniness => ({
  type: actions.SET_SUNNINESS,
  sunniness: sunniness
});

export const setMaxPrice = price => ({
  type: actions.SET_MAX_PRICE,
  price: price
});

export const setToolEffort = toolEffort => ({
  type: actions.SET_TOOL_EFFORT,
  toolEffort: toolEffort
});

export const setStateAndZip = (state, zip) => ({
  type: actions.SET_STATE_AND_ZIP,
  state: state,
  zip: zip
});

export const setHasHighTraffic = hasHighTraffic => ({
  type: actions.SET_HAS_HIGH_TRAFFIC,
  hasHighTraffic: hasHighTraffic
});

export const toggleHasHighTraffic = () => ({
  type: actions.TOGGLE_HAS_HIGH_TRAFFIC
});

export const setHasPetSpots = hasPetSpots => ({
  type: actions.SET_HAS_PET_SPOTS,
  hasPetSpots: hasPetSpots
});

export const toggleHasPetSpots = () => ({
  type: actions.TOGGLE_HAS_PET_SPOTS
});

export const setHasTractor = hasTractor => ({
  type: actions.SET_HAS_TRACTOR,
  hasTractor: hasTractor
});

export const toggleHasTractor = () => ({
  type: actions.TOGGLE_HAS_TRACTOR
});

export const setPlan = plan => ({
  type: actions.SET_PLAN,
  plan: plan
});

export const setUseOrganicFertilizers = useOrganicFertilizers => ({
  type: actions.SET_USE_ORGANIC_FERTILIZERS,
  useOrganicFertilizers: useOrganicFertilizers
});

export const toggleUseOrganicFertilizers = () => ({
  type: actions.TOGGLE_USE_ORGANIC_FERTILIZERS
});

export const setTools = tools => ({
  type: actions.SET_TOOLS,
  tools: tools
});

export const setProducts = products => ({
  type: actions.SET_PRODUCTS,
  products: products
});

export const setActivities = activities => ({
  type: actions.SET_ACTIVITIES,
  activities: activities
});

export const updateDisplayedItems = () => ({
  type: actions.UPDATE_DISPLAYED_ITEMS
});

export const updateCacheTime = time => ({
  type: actions.UPDATE_CACHE_TIME,
  time: time
});

export const togglePromotionalEmails = () => ({
  type: actions.TOGGLE_PROMOTIONAL_EMAILS
});

export const togglePlanReminders = () => ({
  type: actions.TOGGLE_PLAN_REMINDERS
});
