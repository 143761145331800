import BaseModel from './BaseModel';

/**
 * Tool or product used or needed to complete an activity
 * @typedef LawnProduct
 */
class LawnProduct extends BaseModel {

  /**
   * @constructor
   * @override
   * @param data {{}}
   */
  constructor(data){
    super(data);
    const {
      asin,
      image_url,
      name,
      amazon_rating,
      description,
      amazon_price
    } = data;

    /**
     * @type {String}
     */
    this.asin = asin;

    /**
     * @type {String}
     */
    this.image_url = image_url;

    /**
     * @type {String}
     */
    this.name = name;

    /**
     * @type {String}
     */
    this.amazon_rating = amazon_rating;

    /**
     * @type {String}
     */
    this.description = description;

    /**
     * @type {String}
     */
    this.amazon_price = amazon_price;
  }

  /**
   * Returns a simple JSON object representation of the LawnProduct
   * @override
   * @return {{}}
   */
  data = () => ({
    asin: this.asin,
    image_url: this.image_url,
    name: this.name,
    amazon_rating: this.amazon_rating,
    description: this.description,
    amazon_price: this.amazon_price
  })
}

export default LawnProduct;
