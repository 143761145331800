import * as actions from '../actions';
import {sessionService} from 'redux-react-session';
import * as ReactGA from 'react-ga';

function auth(state = {}, action) {
  switch(action.type){
    case actions.LOGOUT:
      sessionService.deleteSession();
      sessionService.deleteUser();
      ReactGA.set({ dimension1: 'Not Logged In' });
      return state;
    default:
      return state;
  }
}

export default auth;
