export const SET_GRASS_SEASON = 'SET_GRASS_SEASON';
export const SET_GRASS = 'SET_GRASS';
export const SET_LAWN_STATE = 'SET_LAWN_STATE';
export const SET_SEASON = 'SET_SEASON';
export const SET_ACTIVITY = 'SET_ACTIVITY';
export const SET_SUNNINESS = 'SET_SUNNINESS';
export const SET_TOOL_EFFORT = 'SET_TOOL_EFFORT';
export const SET_MAX_PRICE = 'SET_MAX_PRICE';
export const SET_STATE_AND_ZIP = 'SET_STATE_AND_ZIP';
export const SET_HAS_HIGH_TRAFFIC = 'SET_HAS_HIGH_TRAFFIC';
export const SET_HAS_PET_SPOTS = 'SET_HAS_PET_SPOTS';
export const SET_HAS_TRACTOR = 'SET_HAS_TRACTOR';
export const SET_USE_ORGANIC_FERTILIZERS = 'SET_USE_ORGANIC_FERTILIZERS';
export const TOGGLE_HAS_HIGH_TRAFFIC = 'TOGGLE_HAS_HIGH_TRAFFIC';
export const TOGGLE_HAS_PET_SPOTS = 'TOGGLE_HAS_PET_SPOTS';
export const TOGGLE_USE_ORGANIC_FERTILIZERS = 'TOGGLE_USE_ORGANIC_FERTILIZERS';
export const TOGGLE_HAS_TRACTOR = 'TOGGLE_HAS_TRACTOR';
export const SET_PLAN = 'SET_PLAN';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const SET_TOOLS = 'SET_TOOLS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const UPDATE_DISPLAYED_ITEMS = 'UPDATE_DISPLAYED_ITEMS';
export const UPDATE_CACHE_TIME = 'UPDATE_CACHE_TIME';

export const GET_STATE_FROM_ZIP = 'GET_STATE_FROM_ZIP';
export const LOAD_ACTIVITIES = 'LOAD_ACTIVITIES';
export const LOAD_TOOLS = 'LOAD_TOOLS';
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_PLAN = 'LOAD_PLAN';
export const SAVE_PLAN = 'SAVE_PLAN';
export const SET_AUTHORIZATION_TOKEN = 'SET_AUTHORIZATION_TOKEN';
export const GET_AUTHORIZATION_TOKEN = 'GET_AUTHORIZATION_TOKEN';
export const SET_AUTHORIZATION_TOKEN_REFRESH_TIMER = 'SET_AUTHORIZATION_TOKEN_REFRESH_TIMER';

export const LOGOUT = 'LOGOUT';
export const PROCESS_LOGIN = 'PROCESS_LOGIN';
export const CLOSE_ACCOUNT = 'CLOSE_ACCOUNT';
export const TOGGLE_PROMOTIONAL_EMAILS = 'TOGGLE_PROMOTIONAL_EMAILS';
export const TOGGLE_PLAN_REMINDERS = 'TOGGLE_PLAN_REMINDERS';
