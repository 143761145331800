import * as actions from '../actions';

const initialState = {
  token: null
};

function api(state = initialState, action) {
  switch(action.type){
    case actions.SET_AUTHORIZATION_TOKEN:
      return Object.assign({}, state, {
        token: action.token
      });
    default:
      return state;
  }
}

export default api;
