export const ZERO = 0;
export const ONE = 1;

export const COMPARE_EQUAL = 0;

export const SORT_BEFORE = -1;
export const SORT_EQUAL = 0;
export const SORT_AFTER = 1;

export const SCREEN_SIZE_MOBILE = 768;
export const SCREEN_X_START = 0;
export const SCREEN_Y_START = 0;

export const ZIP_LENGTH = 5;
export const MAX_LOGIN_RETRY_ATTEMPTS = 3;

export const MIN_1 = 60000;
export const MIN_3 = 180000;
export const MIN_5 = 300000;
export const MIN_10 = 600000;
export const HR_2 = 7200000;

export const STAR_RATING_DEFAULT = 1;
export const STAR_RATING_SUBSTRING_START_INDEX = 0;
export const STAR_RATING_SUBSTRING_END_INDEX = 4;
export const STAR_RATING_1_POINT_5 = 1.5;
export const STAR_RATING_2 = 2;
export const STAR_RATING_2_POINT_5 = 2.5;
export const STAR_RATING_3 = 3;
export const STAR_RATING_3_POINT_5 = 3.5;
export const STAR_RATING_4 = 4;
export const STAR_RATING_4_POINT_5 = 4.5;
export const STAR_RATING_5 = 5;
