import * as actions from '../actions';
import 'whatwg-fetch';
import {HR_2, MIN_10} from '../constants';
import {
  setStateAndZip,
  setActivities,
  setProducts,
  setTools,
  updateDisplayedItems,
  updateCacheTime,
  setPlan
} from '../actions/plan';
import {getAuthorizationToken, setAuthorizationToken, setAuthorizationTokenRefreshTimer} from '../actions/api';
import {sessionService} from 'redux-react-session';

/**
 * Creates default GET request options
 * @param token {?null | string} Signed JWT
 * @param method {'get' | 'put'}
 * @param body {?null | object}
 */
function requestOptions(token = null, method = 'get', body = null) {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: method,
    body: body,
    mode: 'cors'
  };

  if(token !== null){
    options.headers.Authorization = token;
  }

  return options;
}

/**
 * Makes GET requests to LCP API
 * @param apiPath {string} URL path to call
 * @param token {?null | string} Signed JWT
 * @return {Promise<any>}
 */
async function apiCall(apiPath, token = null){
  const response = await fetch(`https://${process.env.REACT_APP_API_BASE_URL}/${apiPath}`, requestOptions(`Bearer: ${token}`));
  return response.json();
}

async function userApiCall(apiPath, method = 'get', body = null){
  const session = await sessionService.loadSession();
  const response = await fetch(`https://${process.env.REACT_APP_API_BASE_URL}/${apiPath}`, requestOptions(session.token, method, body));
  return response.json();
}

const api = store => next => async action => {
  let response;

  /**
   * Tries to retrieve the token from the store, requests a new one if it cannot
   * @return {Promise<string>}
   */
  const getCurrentToken = async () => {
    let token = store.getState().api.token;
    if(token === null){
      await store.dispatch(getAuthorizationToken());
      token = store.getState().api.token;
    }
    return token;
  };

  switch(action.type){
    case actions.LOAD_ACTIVITIES:
      await store.dispatch(setActivities(await apiCall('activities', await getCurrentToken())));
      store.dispatch(updateCacheTime(Date.now() + MIN_10));
      break;
    case actions.LOAD_PRODUCTS:
      await store.dispatch(setProducts(await apiCall('products', await getCurrentToken())));
      store.dispatch(updateCacheTime(Date.now() + MIN_10));
      break;
    case actions.LOAD_TOOLS:
      await store.dispatch(setTools(await apiCall('tools', await getCurrentToken())));
      store.dispatch(updateCacheTime(Date.now() + MIN_10));
      break;
    case actions.LOAD_PLAN:
      response = await userApiCall('plans');
      store.dispatch(setPlan(response.plan));
      break;
    case actions.SAVE_PLAN:
      await userApiCall('plans', 'put', JSON.stringify(store.getState().plan));
      break;
    case actions.GET_STATE_FROM_ZIP:
      response = await apiCall(`states/zip/${action.zip}`, await getCurrentToken());
      await store.dispatch(setStateAndZip(response.state, action.zip));
      store.dispatch(updateDisplayedItems());
      break;
    case actions.GET_AUTHORIZATION_TOKEN:
      response = await apiCall('authorize/web');
      await store.dispatch(setAuthorizationToken(response));
      store.dispatch(setAuthorizationTokenRefreshTimer());
      break;
    case actions.SET_AUTHORIZATION_TOKEN_REFRESH_TIMER:
      setTimeout(() => {
        store.dispatch(setAuthorizationToken(null)); //invalidate current token, forcing next api call to refresh
      }, HR_2 - MIN_10);
      break;
    case actions.CLOSE_ACCOUNT:
      await userApiCall('accounts', 'delete');
      break;
    default:
      break;
  }
  return next(action);
};

export default api;
