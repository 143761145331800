import React, {useCallback, useEffect, lazy, Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {loadActivities, loadProducts, loadTools, getAuthorizationToken, setAuthorizationToken} from './store';
import {connect} from 'react-redux';
import {ZERO} from './store/constants';
import ReactLoading from 'react-loading';
import {withCookies} from 'react-cookie';

const Header = lazy(() => import('./components/Header'));
const Footer = lazy(() => import('./components/Footer'));
const LoginCallback = lazy(() => import('./components/authentication/LoginCallback'));
const LogoutCallback = lazy(() => import('./components/authentication/LogoutCallback'));
const Login = lazy(() => import('./components/authentication/Login'));
const Logout = lazy(() => import('./components/authentication/Logout'));
const PrivateRoute = lazy(() => import('./components/authentication/PrivateRoute'));
const Register = lazy(() => import('./components/authentication/Register'));
const RegisterCallback = lazy(() => import('./components/authentication/RegisterCallback'));

const AboutUs = lazy(() => import('./pages/AboutUs'));
const CreatePlan = lazy(() => import('./pages/CreatePlan'));
const Home = lazy(() => import('./pages/Home'));
const MyPlan = lazy(() => import('./pages/MyPlan'));
const Wiki = lazy(() => import('./pages/Wiki'));
const Profile = lazy(() => import('./pages/Profile'));
const ConfirmCloseAccount = lazy(() => import('./components/authentication/ConfirmCloseAccount'));

export const App = ({
  cookies,
  getAuthorizationToken,
  setAuthorizationToken,
  token,
  isDataLoaded,
  cacheTime,
  loadActivities,
  loadProducts,
  loadTools,
  authenticated,
  checked
}) => {
  const loadAll = useCallback(() => {
    loadActivities();
    loadProducts();
    loadTools();
  }, [loadActivities, loadProducts, loadTools]);

  useEffect(() => {
    //Check for auth cookie
    const authCookie = cookies.get(`lcp-auth-${process.env.REACT_APP_ENVIRONMENT}`);
    if (authCookie === null) {
      getAuthorizationToken().then(response => {
        cookies.set(`lcp-auth-${process.env.REACT_APP_ENVIRONMENT}`, token);
        setAuthorizationToken(response);
      });
    }

    if(!isDataLoaded) {
      loadAll();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cacheTime || cacheTime > Date.now()) {
      loadAll();
    }
  });

  return (
    <Router>
      <Suspense fallback={null}>
        <Header/>
      </Suspense>
      {checked &&
      <Suspense
        fallback={<div className="col sm-12 animated teal"><ReactLoading type="bubbles" color="#5CB8B2" height={128} width={128} className="loading-icon"/></div>}>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/about-us" component={AboutUs}/>
          <Route path="/my-plan" exact component={MyPlan}/>
          <Route path="/my-plan/:season" exact component={MyPlan}/>
          <Route path="/my-plan/:season/:activity" component={MyPlan}/>
          <Route path="/create-plan" component={CreatePlan}/>
          <Route path="/wiki" component={Wiki}/>
          <Route path="/register" exact component={Register}/>
          <Route path="/login" exact component={Login}/>
          <Route path="/logout" exact component={Logout}/>
          <Route path="/authorize/register" component={RegisterCallback}/>
          <Route path="/authorize/login" component={LoginCallback}/>
          <Route path="/authorize/logout" component={LogoutCallback}/>
          <PrivateRoute path="/profile" exact component={Profile} authenticated={authenticated}/>
          <PrivateRoute path="/profile/close" exact component={ConfirmCloseAccount} authenticated={authenticated}/>
        </Switch>
      </Suspense>
      }
      <Suspense fallback={null}>
        <Footer/>
      </Suspense>
    </Router>
  );
};

const mapStateToProps = state => ({
  cacheTime: state.plan.cacheTime,
  token: state.api.token,
  isDataLoaded: state.plan.activities.length > ZERO && state.plan.products.length > ZERO && state.plan.tools.length > ZERO,
  authenticated: state.session.authenticated,
  checked: state.session.checked
});

const actionCreators = {
  loadActivities,
  loadProducts,
  loadTools,
  getAuthorizationToken,
  setAuthorizationToken
};

export default connect(mapStateToProps, actionCreators)(withCookies(App));
